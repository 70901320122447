<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title v-if="!toggle_one">
          Wszystkie płatności
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-title v-else>
          Wszystkie pobrania
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-title>
          <v-col cols="12" sm="3" md="3">
            <v-btn-toggle v-model="toggle_one" mandatory rounded dense>
              <v-btn :value="false"> Płatności </v-btn>
              <v-btn :value="true"> Pobrania </v-btn>
            </v-btn-toggle>
          </v-col>

          <!-- <v-col cols="12" sm="3" md="3">
            <v-spacer></v-spacer>
            <v-btn @click="from = !from">
              <p class="ma-0">Wybierz zakres dat</p>
            </v-btn>
            <v-btn
              @click="
                pickup_date_from = null;
                pickup_date_to = null;
                getDataPayments();
                getDataPickups();
              "
              text
              v-if="pickup_date_from && pickup_date_to"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col> -->
          <v-spacer></v-spacer>

          <v-col cols="12" sm="6" md="6">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchPickups"
              label="Szukaj"
              single-line
              hide-details
              v-if="toggle_one"
            ></v-text-field>
            <v-text-field
              v-model="searchPayments"
              label="Szukaj"
              single-line
              hide-details
              v-else
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-data-table
          :headers="pickupsHeaders"
          :items="pickups"
          class="elevation-1"
          :search="searchPickups"
          :loading="loadingPickups"
          loading-text="Ładowanie... Proszę czekać"
          :options.sync="optionsPickups"
          :server-items-length="totalPickups"
          :footer-props="{
            itemsPerPageOptions: listSize,
            showCurrentPage: true,
            disablePagination: disableFooterPickups,
          }"
          v-show="toggle_one"
        >
          <template v-slot:[`item.p_number`]="{ item }">
            {{ item.p_number }}
          </template>

          <template v-slot:[`item.t_numbers`]="{ item }">
            <a
              v-for="(number, key) in item.t_numbers.split(',')"
              :key="key"
              :href="'/transport/' + item.t_ids.split(',')[key]"
            >
              {{ number }}<span v-if="key < item.t_numbers.split(',').length - 1">,</span>
            </a>
          </template>

          <template v-slot:[`item.user_id`]="{ item }">
            <a :href="'/uzytkownicy/' + item.user_id">
              {{ item.name + ' ' + item.surname }}
            </a>
          </template>

          <template v-slot:[`item.price_net`]="{ item }"> {{ item.price_net }} zł </template>

          <template v-slot:[`item.print`]="{ item }">
            <v-btn icon @click="downloadFile(item, 'pickup')">
              <v-icon> mdi-file </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.pickup_payment_id`]="{ item }">
            <v-tab :to="'/finanse/pobranie/' + item.pickup_payment_id"> Zobacz</v-tab>
          </template>
        </v-data-table>

        <v-data-table
          :headers="paymentsHeaders"
          :items="payments"
          class="elevation-1"
          :search="searchPayments"
          :loading="loadingPayments"
          loading-text="Ładowanie... Proszę czekać"
          :options.sync="optionsPayments"
          :server-items-length="totalPayments"
          :footer-props="{
            itemsPerPageOptions: listSize,
            showCurrentPage: true,
            disablePagination: disableFooterPayments,
          }"
          v-show="!toggle_one"
        >
          <template v-slot:[`item.p_number`]="{ item }">
            {{ item.p_number }}
          </template>

          <template v-slot:[`item.t_numbers`]="{ item }">
            <a
              v-for="(number, key) in item.t_numbers.split(',')"
              :key="key"
              :href="'/transport/' + item.t_ids.split(',')[key]"
            >
              {{ number }}<span v-if="key < item.t_numbers.split(',').length - 1">,</span>
            </a>
          </template>

          <template v-slot:[`item.user_id`]="{ item }">
            <a :href="'/uzytkownicy/' + item.user_id">
              {{ item.name + ' ' + item.surname }}
            </a>
          </template>

          <template v-slot:[`item.price_net`]="{ item }">
            {{ `${item.price_net}\xa0zł` }}
          </template>

          <template v-slot:[`item.price_gross`]="{ item }">
            {{ `${item.price_gross}\xa0zł` }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.date_payed"
              color="green"
              dark
              @click="openPaymentStatusDialog(item)"
            >
              Opłacone
            </v-chip>
            <v-chip v-else color="red" dark @click="openPaymentStatusDialog(item)">
              Nieopłacone
            </v-chip>
          </template>

          <template v-slot:[`item.date_expected`]="{ item }">
            {{ item.date_expected }}
          </template>

          <template v-slot:[`item.invoice_id`]="{ item }">
            <v-btn v-if="item.invoice_id" color="red" icon @click="downloadInvoice(item)">
              <v-icon> mdi-file </v-icon>
            </v-btn>

            <v-btn v-else color="green" icon @click="addInvoice(item)">
              <v-icon> mdi-plus-circle </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.printFull`]="{ item }">
            <v-btn icon @click="downloadFile(item, 'payment')">
              <v-icon> mdi-file </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.payment_id`]="{ item }">
            <v-tab :to="'/finanse/platnosc/' + item.payment_id"> Zobacz</v-tab>
          </template>

          <!-- <template v-slot:[`item.print`]="{ item }">
            <v-icon @click="downloadPDF(item, 'payment')"> mdi-file </v-icon>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="from" :dark="false" :light="true" max-width="410px">
      <v-card class="mx-auto" max-width="800" outlined>
        <v-toolbar flat>
          <v-toolbar-title>Data zapłaty od</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-col cols="12" sm="12" md="12">
          <v-date-picker
            v-model="pickup_date_from"
            color="amber darken-1"
            :first-day-of-week="1"
            locale="pl-PL"
            full-width
          ></v-date-picker>
        </v-col>

        <v-row class="ma-0 pa-auto">
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                from = !from;
                clearDatePicker();
              "
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Anuluj
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                from = !from;
                to = !to;
              "
              :disabled="!pickup_date_from"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Dalej
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="to" :dark="false" :light="true" max-width="410px">
      <v-card class="mx-auto" max-width="800" outlined>
        <v-toolbar flat>
          <v-toolbar-title>Data zapłaty do</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-col cols="12" sm="12" md="12">
          <v-date-picker
            v-model="pickup_date_to"
            color="amber darken-1"
            :first-day-of-week="1"
            locale="pl-PL"
            full-width
          ></v-date-picker>
        </v-col>

        <v-row class="ma-0 pa-auto">
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                to = !to;
                clearDatePicker();
              "
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Anuluj
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                getDataPayments();
                getDataPickups();
                to = !to;
              "
              :disabled="!pickup_date_to"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Zatwierdz
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <AddInvoiceDialog
      v-model="addInvoiceDialog"
      :payment="editingPayment"
      @save="invoiceAdded()"
    ></AddInvoiceDialog>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <!-- payment status dialog -->
    <PaymentStatus
      v-model="paymentStatusDialog"
      :payment="editingPayment"
      @submit="editPaymentStatus"
    ></PaymentStatus>

    <FileFormatDialog ref="fileFormatDialog"></FileFormatDialog>
  </v-container>
</template>

<script>
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';
  import axios from 'axios';
  import moment from 'moment';

  import AddInvoiceDialog from '../../../components/AddInvoiceDialog.vue';
  import InfoSnackBar from '../../../components/InfoSnackBar.vue';
  import PaymentStatus from '../../../components/PaymentStatus.vue';
  import FileFormatDialog from '../../../components/FileFormatDialog.vue';

  export default {
    components: {
      AddInvoiceDialog,
      InfoSnackBar,
      PaymentStatus,
      FileFormatDialog,
    },
    data: () => ({
      info: null,

      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      paymentsHeaders: [
        {
          text: 'Numer płatności',
          align: 'left',
          value: 'p_number',
        },
        {
          text: 'Objęte transporty',
          value: 't_numbers',
          sortable: false,
        },
        {
          text: 'Klient',
          value: 'user_id',
        },
        {
          text: 'Cena netto',
          value: 'price_net',
          sortable: false,
          // width: 78,
        },
        {
          text: 'Cena brutto',
          value: 'price_gross',
          sortable: false,
          // width: 84,
        },
        {
          text: 'Data utworzenia',
          value: 'date_created',
          width: 110,
        },
        {
          text: 'Status',
          value: 'status',
        },
        // {
        //   text: 'Data zapłaty',
        //   value: 'date_payed',
        //   width: 120,
        // },
        {
          text: 'Termin zapłaty',
          value: 'date_expected',
          width: 132,
        },
        {
          text: 'Faktura',
          value: 'invoice_id',
          sortable: false,
        },
        {
          text: 'Wydruk',
          value: 'printFull',
          sortable: false,
        },
        // {
        //   text: 'Wydruk',
        //   value: 'print',
        //   sortable: false
        // }
        {
          text: 'Szczegóły',
          value: 'payment_id',
          sortable: false,
        },
      ],
      pickupsHeaders: [
        {
          text: 'Numer pobrania',
          align: 'left',
          value: 'p_number',
        },
        {
          text: 'Objęte transporty',
          value: 't_numbers',
          sortable: false,
        },
        {
          text: 'Klient',
          value: 'user_id',
        },
        {
          text: 'Kwota pobrania',
          value: 'price_net',
        },
        {
          text: 'Data utworzenia',
          value: 'date_created',
          width: 110,
        },
        {
          text: 'Data zapłaty',
          value: 'date_payed',
          width: 120,
        },
        {
          text: 'Wydruk',
          value: 'print',
          sortable: false,
        },
        {
          text: 'Szczegóły',
          value: 'pickup_payment_id',
          sortable: false,
        },
      ],

      payments: [],
      prevPayments: {
        page: -1,
        payments: [],
      },
      nextPayments: {
        page: 1,
        payments: [],
      },
      loadingPayments: false,
      optionsPayments: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['p_number'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      searchPayments: null,
      totalPayments: 0,
      disableFooterPayments: false,

      pickups: [],
      prevPickups: {
        page: -1,
        pickups: [],
      },
      nextPickups: {
        page: 1,
        pickups: [],
      },
      loadingPickups: false,
      optionsPickups: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['p_number'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      searchPickups: null,
      totalPickups: 0,
      disableFooterPickups: false,

      toggle_one: false,
      from: false,
      to: false,

      pickup_date_from: null,
      pickup_date_to: null,

      listSize: [10, 20, 50, 100],

      addInvoiceDialog: false,
      editingPayment: {},

      paymentStatusDialog: false,
    }),
    watch: {
      searchPayments: function (str) {
        let ref = this;
        setTimeout(function () {
          if (ref.searchPayments === (' ' + str).slice(1)) {
            ref.optionsPayments.page = NaN;
          }
        }, 600);
      },
      searchPickups: function (str) {
        let ref = this;
        setTimeout(function () {
          if (ref.searchPickups === (' ' + str).slice(1)) {
            ref.optionsPickups.page = NaN;
          }
        }, 600);
      },
      'optionsPickups.sortBy': function (next, before) {
        if (next !== before) {
          this.optionsPickups.page = NaN;
        }
      },
      'optionsPayments.sortBy': function (next, before) {
        if (next !== before) {
          this.optionsPayments.page = NaN;
        }
      },
      // 'optionsPickups.sortDesc': function(next, before) {
      //   if(next !== before) {
      //     this.getDataPickups();
      //   }
      // },
      // 'optionsPayments.sortDesc': function(next, before) {
      //   if(next !== before) {
      //     this.getDataPayments();
      //   }
      // },
      'optionsPickups.itemsPerPage': function (next, before) {
        if (next !== before) {
          this.optionsPickups.page = NaN;
        }
      },
      'optionsPayments.itemsPerPage': function (next, before) {
        if (next !== before) {
          this.optionsPayments.page = NaN;
        }
      },
      'optionsPickups.page': async function (next, before) {
        if (isNaN(before)) {
          this.totalPickups = 0;
          this.getDataPickups();
        } else if (isNaN(next)) {
          this.optionsPickups.page = 1;
        } else if (next === before + 1) {
          this.disableFooterPickups = true;
          this.prevPickups.pickups = this.pickups;
          this.prevPickups.page = before;

          this.pickups = this.nextPickups.pickups;

          this.nextPickups.page = next + 1;
          let newNext = await this.getPickups(next + 1);
          this.nextPickups.pickups = newNext.payments;
          this.disableFooterPickups = false;
        } else if (next === before - 1) {
          this.disableFooterPickups = true;
          this.nextPickups.pickups = this.pickups;
          this.nextPickups.page = before;

          this.pickups = this.prevPickups.pickups;

          this.prevPickups.page = next - 1;
          let newPrev = await this.getPickups(next - 1);
          this.prevPickups.pickups = newPrev.payments;
          this.disableFooterPickups = false;
        }
      },
      'optionsPayments.page': async function (next, before) {
        if (isNaN(before)) {
          this.totalPayments = 0;
          this.getDataPayments();
        } else if (isNaN(next)) {
          this.optionsPayments.page = 1;
        } else if (next === before + 1) {
          this.disableFooterPayments = true;
          this.prevPayments.payments = this.payments;
          this.prevPayments.page = before;

          this.payments = this.nextPayments.payments;

          this.nextPayments.page = next + 1;
          let newNext = await this.getPayments(next + 1);
          this.nextPayments.payments = newNext.payments;
          this.disableFooterPayments = false;
        } else if (next === before - 1) {
          this.disableFooterPayments = true;
          this.nextPayments.payments = this.payments;
          this.nextPayments.page = before;

          this.payments = this.prevPayments.payments;

          this.prevPayments.page = next - 1;
          let newPrev = await this.getPayments(next - 1);
          this.prevPayments.payments = newPrev.payments;
          this.disableFooterPayments = false;
        }
      },
    },
    methods: {
      addInvoice(item) {
        this.addInvoiceDialog = !this.addInvoiceDialog;
        this.editingPayment = item;
      },
      invoiceAdded() {
        this.info = {
          message: 'Faktura została dodana',
          status: 'success',
          timeout: 2000,
        };
      },
      clearDatePicker() {
        if (!this.pickup_date_from || !this.pickup_date_to) {
          this.pickup_date_from = null;
          this.pickup_date_to = null;
        }
      },
      getPayments(n) {
        if (n < 1)
          return {
            payments: [],
            total: 0,
          };

        // eslint-disable-next-line no-unused-vars
        const { sortBy, sortDesc, page, itemsPerPage } = this.optionsPayments;
        let from = (n - 1) * itemsPerPage;
        let to = n * itemsPerPage;

        let search;
        if (this.searchPayments) {
          search = {
            p_number: this.searchPayments,
            'concat(name, " ", surname)': this.searchPayments,
            'concat(surname, " ", name)': this.searchPayments,
            //  name: this.searchPayments,
            //  surname: this.searchPayments,
            union: {
              t_numbers: this.searchPayments,
            },
          };
        } else {
          search = null;
        }

        return axios({
          url: this.$store.state.url + '/api/payment/search',
          data: {
            token: this.$store.state.token,
            type: 'join',
            query: true,
            sortBy: sortBy,
            sortDesc: sortDesc,
            from: from,
            to: to,
            search: search,
            date_from: this.pickup_date_from,
            date_to: this.pickup_date_to,
            transport: true,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              for (let [, t] of resp.data.payments.entries()) {
                if (t.date_created)
                  t.date_created = moment(new Date(t.date_created)).format('DD-MM-YYYY');
                if (t.date_payed) t.date_payed = moment(new Date(t.date_payed)).format('DD-MM-YYYY');
                if (t.date_expected)
                  t.date_expected = moment(new Date(t.date_expected)).format('DD-MM-YYYY');
              }
              return resp.data;
            } else {
              return {
                payments: [],
                total: 0,
              };
            }
          })
          .catch();
      },
      getPickups(n) {
        if (n < 1) {
          return {
            payments: [],
            total: 0,
          };
        }
        // eslint-disable-next-line no-unused-vars
        const { sortBy, sortDesc, page, itemsPerPage } = this.optionsPickups;
        let from = (n - 1) * itemsPerPage;
        let to = n * itemsPerPage;

        let search;
        if (this.searchPickups) {
          search = {
            p_number: this.searchPickups,
            'concat(name, " ", surname)': this.searchPickups,
            'concat(surname, " ", name)': this.searchPickups,
            //  name: this.searchPickups,
            //  surname: this.searchPickups,
            union: {
              t_numbers: this.searchPickups,
            },
          };
        } else {
          search = null;
        }

        return axios({
          url: this.$store.state.url + '/api/payment/pickup/search',
          data: {
            token: this.$store.state.token,
            type: 'join',
            query: true,
            sortBy: sortBy,
            sortDesc: sortDesc,
            from: from,
            to: to,
            search: search,
            date_from: this.pickup_date_from,
            date_to: this.pickup_date_to,
            transport: true,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              for (let [, t] of resp.data.payments.entries()) {
                if (t.date_created)
                  t.date_created = moment(new Date(t.date_created)).format('DD-MM-YYYY');
                if (t.date_payed) t.date_payed = moment(new Date(t.date_payed)).format('DD-MM-YYYY');
              }
              return resp.data;
            } else {
              return {
                payments: [],
                total: 0,
              };
            }
          })
          .catch();
      },
      async getDataPayments() {
        this.disableFooterPayments = true;
        this.loadingPayments = true;
        let newData = await this.getPayments(1);
        this.payments = newData.payments;
        this.totalPayments = newData.total;
        this.loadingPayments = false;

        this.nextPayments.page = 2;
        let newNext = await this.getPayments(2);
        this.nextPayments.payments = newNext.payments;

        this.prevPayments.page = 0;
        let newPrev = await this.getPayments(0);
        this.prevPayments.payments = newPrev.payments;
        this.disableFooterPayments = false;
      },
      async getDataPickups() {
        this.disableFooterPickups = true;
        this.loadingPickups = true;
        let newData = await this.getPickups(1);
        this.pickups = newData.payments;
        this.totalPickups = newData.total;
        this.loadingPickups = false;

        this.nextPickups.page = 2;
        let newNext = await this.getPickups(2);
        this.nextPickups.pickups = newNext.payments;

        this.prevPickups.page = 0;
        let newPrev = await this.getPickups(0);
        this.prevPickups.pickups = newPrev.payments;
        this.disableFooterPickups = false;
      },
      downloadInvoice: function (invoice) {
        if (typeof invoice === 'object' && invoice && invoice.invoice_id)
          invoice = invoice.invoice_id;

        axios({
          url: this.$store.state.url + '/api/invoice/' + invoice,
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
          method: 'GET',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute(
                'download',
                resp.headers['content-disposition'].split('filename=')[1].split(';')[0].slice(1, -1)
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          })
          .catch(err => {
            if (err.response)
              this.info = {
                message: err.response.data.message || 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
            else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          });
      },
      downloadFile: async function (item, type) {
        const fileType = await this.$refs.fileFormatDialog.open();
        if (!fileType || typeof fileType !== 'string') return;

        switch (fileType.toLowerCase()) {
          case 'pdf':
            this.downloadPDF(item, type, true);
            break;
          case 'csv':
            this.downloadCSV(item, type);
            break;
        }
      },
      downloadCSV: function (item, type) {
        let url_base = this.$store.state.url + '/api';
        if ('pickup' === type) url_base += '/payment/pickup/get_csv';
        else url_base += '/payment/get_csv';

        axios({
          url: url_base,
          data: {
            token: this.$store.state.token,
            id: type === 'pickup' ? item.pickup_payment_id : item.payment_id,
          },
          method: 'POST',
          responseType: 'blob',
        })
          .then(res => {
            if (res.status === 200) {
              const fileURL = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute(
                'download',
                res.headers['content-disposition'].split('filename=')[1].split(';')[0].slice(1, -1) ||
                  `${type}_${item.p_number}.csv`
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          })
          .catch(err => {
            if (err.response)
              this.info = {
                message: err.response.data.message || 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
            else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          });
      },
      downloadPDF: function (item, type, full = false) {
        let url_base = this.$store.state.url + '/api';
        if ('pickup' === type) url_base += '/payment/pickup/get_pdf';
        else url_base += '/payment/get_pdf';

        axios({
          url: url_base,
          data: {
            token: this.$store.state.token,
            id: 'pickup' === type ? item.pickup_payment_id : item.payment_id,
            full: full,
          },
          method: 'POST',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute(
                'download',
                `${'pickup' === type ? 'pickup' : 'payment'}_${item.p_number}.pdf`
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else {
              if (resp.data.message) alert(resp.data.message);
              else alert('Niepowiodło się');
            }
          })
          .catch(() => {
            alert('Niepowiodło się');
          });
      },
      openPaymentStatusDialog: function (item) {
        this.editingPayment = item;
        this.paymentStatusDialog = true;
      },
      editPaymentStatus: function (status, date) {
        if (!this.editingPayment) return;

        axios({
          url: this.$store.state.url + '/api/payment/edit_status',
          data: {
            token: this.$store.state.token,
            id: this.editingPayment.payment_id,
            status: status,
            date: date,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.status === 200) {
              this.editingPayment.date_payed = date;
              this.paymentStatusDialog = false;

              this.info = {
                message: resp.data.message,
                status: 'success',
                timeout: 1500,
              };
            } else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          })
          .catch(err => {
            if (err.response)
              this.info = {
                message: err.response.data.message || 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
            else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          });
      },
    },
    created() {
      this.searchPayments = this.$route.params.search
        ? decodeURIComponent(this.$route.params.search)
        : null;
      this.searchPickups = this.searchPayments;

      this.getDataPayments();
      this.getDataPickups();

      // axios({
      //   url: this.$store.state.url + '/api/payment/search',
      //   data: {
      //     token: this.$store.state.token,
      //     search: this.searchPayments,
      //     sortBy: this.optionsPayments.sortBy,
      //     sortDesc: this.optionsPayments.sortDesc,
      //     transport: true
      //   },
      //   method: 'POST'
      // }).then(resp => {
      //   if(resp.data.status === 200) {
      //     for( let [,t] of resp.data.payments.entries() ) {
      //       t.date_created = new Date(t.date_created).toLocaleString('pl-PL');
      //       t.date_payed = new Date(t.date_payed).toLocaleString('pl-PL').split(',')[0];
      //     }
      //     this.payments = resp.data.payments;
      //     this.totalPayments = resp.data.total;
      //   } else {
      //     alert("Error");
      //   }
      // }).catch();

      // axios({
      //   url: this.$store.state.url + '/api/payment/pickup/search',
      //   data: {
      //     token: this.$store.state.token,
      //     search: this.searchPickups,
      //     sortBy: this.optionsPickups.sortBy,
      //     sortDesc: this.optionsPickups.sortDesc,
      //     transport: true
      //   },
      //   method: 'POST'
      // }).then(resp => {
      //   if(resp.data.status === 200) {
      //     for( let [,t] of resp.data.payments.entries() ) {
      //       t.date_created = new Date(t.date_created).toLocaleString('pl-PL');
      //       t.date_payed = new Date(t.date_payed).toLocaleString('pl-PL').split(',')[0];
      //     }
      //     this.pickups = resp.data.payments;
      //     this.totalPickups = resp.data.total;
      //   } else {
      //     alert("Error");
      //   }
      // }).catch();
    },
  };
</script>
<style scoped>
  a:hover {
    text-decoration: underline;
  }
</style>
