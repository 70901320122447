<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline ma-0 pa-0"> Zmień status </v-list-item-title>
              <v-list-item-subtitle class="mt-n4">
                Tutaj możesz zmienić status płatności
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 grey--text text--darken-4">
        <v-row justify="center" align="center" no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-radio-group v-model="status" hide-details dense class="ma-0">
              <v-radio
                v-for="s in availableStatuses"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="mt-4"
            v-if="availableStatuses[0].name !== 'Nieopłacone'"
          >
            <template v-if="editDate">
              <v-date-picker
                v-model="selectedDate"
                year-icon="mdi-calendar-blank"
                color="amber darken-1"
                :first-day-of-week="1"
                locale="pl-PL"
                full-width
                elevation="6"
              ></v-date-picker>
            </template>

            <template v-else>
              <h3>
                Opłacono dnia:
                <v-btn icon color="primary" small @click="editDate = true">
                  <v-icon> mdi-circle-edit-outline </v-icon>
                </v-btn>
              </h3>

              <h3>{{ this.selectedDate.split('-').reverse().join('-') }}</h3>
            </template>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="close"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="submit"
          :disabled="status == null"
        >
          Edytuj
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'PaymentStatus',
    components: {},
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      payment: {
        type: Object,
      },
      width: {
        type: String,
        default: '620px',
      },
    },
    data: () => ({
      status: 0,
      editDate: false,
      selectedDate: moment().format('YYYY-MM-DD'),
    }),
    watch: {
      value: function (newVal) {
        if (newVal === false) this.clean();
      },
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      availableStatuses: function () {
        return this.payment && this.payment.date_payed
          ? [{ id: 0, name: 'Nieopłacone' }]
          : [{ id: 0, name: 'Opłacone' }];
      },
    },
    methods: {
      clean: function () {
        this.status = 0;
        this.editDate = false;
        this.selectedDate = moment().format('YYYY-MM-DD');
      },
      close: function () {
        this.isActive = false;
      },
      submit: function () {
        this.$emit(
          'submit',
          this.availableStatuses[0].name == 'Nieopłacone' ? 0 : 1,
          this.availableStatuses[0].name == 'Nieopłacone' ? null : this.selectedDate
        );
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style></style>
