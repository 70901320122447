<template>
  <v-dialog v-model="isActive" persistent max-width="600px">
    <v-card>
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title v-if="payment.invoice_id">Edytuj fakturę</v-toolbar-title>
        <v-toolbar-title v-else>Dodaj fakturę</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-4">
        <v-row align="center" justify="center">
          <v-col lg="12" md="12" sm="12" cols="12">
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              v-on:vdropzone-success="onSuccess"
            ></vue-dropzone>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          :x-large="true"
          v-tilt
          @click="close"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <!-- <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          x-large
          v-tilt
          @click="save"
        >
          Zatwierdz
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import vue2Dropzone from 'vue2-dropzone';
  import urljoin from 'url-join';
  import store from '../store';

  export default {
    name: 'AddInvoiceDialog',
    components: {
      vueDropzone: vue2Dropzone,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      payment: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      dropzoneOptions: {
        url: urljoin(store.state.url, '/api/payment/:id/invoice'),
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
        thumbnailWidth: 150,
        maxFiles: 1,
        dictDefaultMessage: 'Wybierz fakturę (jpg, png, pdf, doc)',
        acceptedFiles:
          'application/pdf,image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
    }),
    watch: {
      'payment.payment_id': function (newVal) {
        if (newVal)
          this.dropzoneOptions.url = urljoin(store.state.url, `/api/payment/${newVal}/invoice`);
        else this.dropzoneOptions.url = urljoin(store.state.url, '/api/payment/:id/invoice');
      },
      // isActive() {
      //   // this.reset();
      // },
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    methods: {
      onSuccess: function (_file, response) {
        Object.assign(this.payment, { invoice_id: response.payment.invoice_id });
        this.$emit('save', response.payment);
        this.close();
      },
      close() {
        this.$refs.myVueDropzone.removeAllFiles();
        this.$emit('input', false);
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
</style>
